//@flow
import React, { PureComponent } from 'react';

import type { FindingTargetStatusEnum } from '@dt/enums/FindingTargetStatusEnum';
import CircularProgress from '@mui/material/CircularProgress';
import { palette } from '@dt/theme';

import { statusToFriendlyString, isClosedStatus, isNeutralStatus } from '@dt/findings/targets/status';

import Indicator from './Indicator';

type Props = {
  status: FindingTargetStatusEnum,
  loading?: ?boolean,
  onClick?: ?(SyntheticEvent<HTMLElement>) => void,
  ...
};

export default class TargetStatus extends PureComponent<Props> {
  render() {
    const { status, loading, onClick } = this.props;

    return (
      <Indicator
        label={
          loading ? (
            <CircularProgress size={14} thickness={2} style={{ marginTop: 2 }} />
          ) : (
            statusToFriendlyString(status)
          )
        }
        onClick={onClick}
        backgroundColor={palette[isNeutralStatus(status) ? 'gray45' : isClosedStatus(status) ? 'green50' : 'red50']}
        color={palette[isNeutralStatus(status) ? 'gray10' : isClosedStatus(status) ? 'green10' : 'red10']}
      />
    );
  }
}
