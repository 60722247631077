// @flow
import React, { memo } from 'react';
import { List, ListItem, ListItemIcon, Avatar, Checkbox } from '@mui/material';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import WebAppIcon from '@mui/icons-material/Web';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import { Text } from '@dt/material-components';
import { AssetTypeEnumValues } from '@dt/graphql-support/types';
import type { AssetTypeEnum } from '@dt/graphql-support/types';

const useStyles = makeStyles(theme => ({
  mainList: {
    maxHeight: 350,
    overflow: 'auto',
    overflowX: 'auto',
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(3),
  },
  itemLogo: {
    justifyContent: 'center',
  },
}));

type Props = {
  selectedList: Array<?AssetTypeEnum>,
  onChange: (Array<?Object>) => void,
};

const TableFiltersAppType = ({ selectedList, onChange }) => {
  const classes = useStyles();

  const handleChange = id => {
    if (selectedList.includes(id)) {
      onChange(selectedList.filter(item => item !== id));
    } else {
      onChange(selectedList.concat(id));
    }
  };

  return (
    <List className={classes.mainList} component="nav">
      <ListItem
        button
        dense
        selected={selectedList.includes(AssetTypeEnumValues.MOBILE_APPLICATION)}
        onClick={() => handleChange(AssetTypeEnumValues.MOBILE_APPLICATION)}
      >
        <Checkbox checked={Boolean(selectedList.includes(AssetTypeEnumValues.MOBILE_APPLICATION))} />
        <ListItemIcon className={classes.itemLogo}>
          <Avatar style={{ height: 24, width: 24 }}>
            <SmartphoneIcon style={{ fontSize: 16, fill: palette.gray20 }} />
          </Avatar>
        </ListItemIcon>
        <Text component={'div'} variant={'body'} noWrap>
          My Mobile Apps
        </Text>
      </ListItem>

      <ListItem
        button
        dense
        selected={selectedList.includes(AssetTypeEnumValues.WEB_APPLICATION)}
        onClick={() => handleChange(AssetTypeEnumValues.WEB_APPLICATION)}
      >
        <Checkbox checked={Boolean(selectedList.includes(AssetTypeEnumValues.WEB_APPLICATION))} />
        <ListItemIcon className={classes.itemLogo}>
          <Avatar style={{ height: 24, width: 24 }}>
            <WebAppIcon style={{ fontSize: 16, fill: palette.gray20 }} />
          </Avatar>
        </ListItemIcon>
        <Text component={'div'} variant={'body'} noWrap>
          My Web Apps
        </Text>
      </ListItem>
    </List>
  );
};

export default memo<Props>(TableFiltersAppType);
