//@flow
import React, { PureComponent } from 'react';
import Divider from '@mui/material/Divider';

const styles: Object = {
  divider: {
    marginLeft: 23,
    marginRight: 23,
  },
};

styles.dividerWithSpace = {
  ...styles.divider,
  marginTop: 28,
};

type Props = {
  space?: boolean,
  style?: Object,
};

export default class MenuDivider extends PureComponent<Props> {
  render() {
    const { space, style } = this.props;
    return (
      <div style={Object.assign({}, style, space ? styles.dividerWithSpace : styles.divider)}>
        <Divider />
      </div>
    );
  }
}
