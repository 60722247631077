// @flow

import type { AlertsIntegrationsListQuery, AlertsIntegrationsListQueryVariables } from '@dt/graphql-support/types';
import { Card, CardContent, Grid, IconButton, Snackbar } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import { AlertsIntegrationsTypeEnumValues } from '@dt/graphql-support/types';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { ExtLink } from '@dt/material-components';
import FormControlLabel from '@mui/material/FormControlLabel';
import Text from '@dt/material-components/Text';
import alerts_integrations from '@dt/graphql-support/public/integrations/alerts_integrations';
import { palette } from '@dt/theme';
import useAwsSecurityHub from './use_aws_security_hub';
import { useQuery } from '@apollo/client';
import ThemedSwitch from './ThemedSwitch';

type Props = {};

export type SnackbarStatus = 'hidden' | 'success' | 'error';

const linkStyle = {
  textDecoration: 'underline',
  color: palette.brand35,
  fontSize: '14px',
};

const listQueryVariables: AlertsIntegrationsListQueryVariables = {
  filter_by_integration_type: [AlertsIntegrationsTypeEnumValues.AWS_SECURITY_HUB],
};

const AwsSecurityHubConfiguration = () => {
  const [snackbarStatus, setSnackbarStatus] = useState<SnackbarStatus>('hidden');
  const { createMutation, deleteMutation } = useAwsSecurityHub({
    setSnackbarStatus,
    queryVariables: listQueryVariables,
  });

  const [create, { loading: loadingCreate, error: errorCreate }] = createMutation;

  const [deleteIntegration, { loading: loadingMutation, error: errorDelete }] = deleteMutation;

  const { data, loading, error } = useQuery<AlertsIntegrationsListQuery, AlertsIntegrationsListQueryVariables>(
    alerts_integrations.list,
    {
      variables: listQueryVariables,
    },
  );

  const integrations = data?.alerts_integrations_list.alerts_integrations;
  const isEnabled = (integrations || []).length > 0;
  const isLoading = loading || loadingMutation || loadingCreate;
  const isError = error || errorDelete || errorCreate;

  const handleOnChange = () => {
    if (isEnabled) {
      const integrationId = integrations?.[0].id;
      if (integrationId) {
        deleteIntegration({
          variables: {
            id: integrations?.[0]?.id,
          },
        });
      }
    } else {
      create({
        variables: {
          body: {
            integration_type: AlertsIntegrationsTypeEnumValues.AWS_SECURITY_HUB,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (isError) {
      setSnackbarStatus('error');
    }
  }, [isError]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Text variant="body">Export API/Web/Cloud Secure policy violations as findings to AWS Security Hub.</Text>
            {/* TODO: Update URL */}
            <ExtLink
              to="https://datatheorem.atlassian.net/wiki/spaces/PKB/pages/1730347009/AWS+Security+Hub+Integration"
              style={linkStyle}
            >
              Integration Document
            </ExtLink>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Text variant="titleXS">How to set up DT&apos;s AWS Security Hub Integration</Text>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Text variant="body">Please ensure that you already set up AWS Security Hub.</Text>
            <Text variant="body">
              For each onboarded AWS accounts, please follow the steps below to set up the integration:
            </Text>
            <ol style={{ paddingLeft: 24 }}>
              <li>
                <Text variant="body">Log in to the AWS Console</Text>
              </li>
              <li>
                <Text variant="body">Go the AWS Security Hub console</Text>
              </li>
              <li>
                <Text variant="body">Click on Integrations</Text>
              </li>
              <li>
                <Text variant="body">Search for Data Theorem in the search box</Text>
              </li>
              <li>
                <Text variant="body">Click on the Accept findings button</Text>
              </li>
              <li>
                <Text variant="body">Once subscribed, new policy violations will automatically be exported</Text>
              </li>
            </ol>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Text variant="titleXS">Settings</Text>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="space-between">
              <Text variant="body">Export Policy Violations to AWS Security Hub</Text>
              <FormControlLabel
                disabled={isLoading}
                control={<ThemedSwitch onChange={handleOnChange} />}
                label={isEnabled ? 'Enabled' : 'Disabled'}
                checked={isEnabled}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={['error', 'success'].includes(snackbarStatus)}
        autoHideDuration={2000}
      >
        <Alert
          variant="filled"
          severity={snackbarStatus === 'error' ? 'error' : 'success'}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSnackbarStatus('hidden');
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {snackbarStatus === 'error'
            ? 'Oops! Something went wrong, please try again later'
            : 'Your integration has been updated successfully'}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default memo<Props>(AwsSecurityHubConfiguration);
