// @flow
import React, { PureComponent } from 'react';
import UrgentCriticalIcon from '@mui/icons-material/Warning';
import ImportantWarningIcon from '@mui/icons-material/Flag';
import InformationalProactiveIcon from '@mui/icons-material/Info';
import ResolvedResolutionIcon from '@mui/icons-material/ThumbUp';
import { palette, oldPaletteToNewPaletteMap, type OldPaletteToNewPaletteType } from '@dt/theme';

type Props = {
  type: OldPaletteToNewPaletteType,
  huge?: boolean,
  tiny?: boolean,
  color?: $Values<typeof palette>,
  ...
};

export default class PolicyViolationIcon extends PureComponent<Props> {
  render() {
    const { type, huge, tiny, color } = this.props;

    const size = huge ? 48 : tiny ? 12 : 18;

    const props = {
      style: {
        width: size,
        height: size,
        color: color ? color : oldPaletteToNewPaletteMap[type],
      },
    };

    if (type === 'bad') {
      return <UrgentCriticalIcon {...props} />;
    } else if (type === 'warning') {
      return <ImportantWarningIcon {...props} />;
    } else if (type === 'info') {
      return <InformationalProactiveIcon {...props} />;
    } else if (type === 'good') {
      return <ResolvedResolutionIcon {...props} />;
    } else if (type === 'neutral') {
      return <ResolvedResolutionIcon {...props} />;
    } else {
      throw new Error('invalid type prop passed to PolicyViolationIcon');
    }
  }
}
