// @flow

import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

type Props<T> = {
  variant?: 'standard' | 'outlined' | 'filled',
  classNameInput?: string,
  options: $ReadOnlyArray<{| +label: string, +value: T |}>,
  value: string,
  placeholder?: string,
  label?: string,
  onChange: T => mixed,
};

const FilterSelectField = <T: string | number>(props: Props<T>) => {
  return (
    <FormControl variant={props.variant || 'standard'}>
      {props.label && (
        <InputLabel htmlFor={props.label.replace(/ /, '_')} shrink={true}>
          {props.label}
        </InputLabel>
      )}
      <Select
        id={props.label && props.label.replace(/ /, '_')}
        value={props.value}
        displayEmpty
        classes={{
          ...(props.classNameInput ? { root: props.classNameInput } : {}),
        }}
        renderValue={selected => {
          if (!selected) {
            return <em>{props.placeholder || 'None'}</em>;
          }

          if (typeof selected !== 'string') {
            throw new Error('expected this select to only have string values');
          }

          const option = props.options.find(l => l.value === selected);
          if (!option) return selected;
          return option.label;
        }}
        onChange={e => {
          const value = e.target.value;
          const option = props.options.find(l => l.value === value);
          if (option) {
            props.onChange(option.value);
          } else {
            throw new Error('An option was selected that should be impossible');
          }
        }}
      >
        {props.options.map(o => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterSelectField;
