// @flow
import React from 'react';
import { Waypoint } from 'react-waypoint';
import { Grid, CircularProgress } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from '@mui/styles';
import CloudLeaksTableHead from './ToolkitAssetTableHead';
import ToolkitTablePolicyViolationRow from './ToolkitAssetTablePolicyViolationRow';
import ToolkitTableAssetRow from './ToolkitAssetTableRow';
import type {
  PolicyViolationsListQueryVersion2Query,
  CloudResourcesListQuery,
  ApiOperationsListQueryQuery,
  SpecialScanStatus,
  AssetTypeEnum,
} from '@dt/graphql-support/types';

const useStyles = makeStyles({
  tableContainer: {
    height: 400,
    paddingRight: 4,
  },
  fetchMoreLoading: {
    padding: 8,
  },
});

type PolicyViolationType = $ElementType<
  $PropertyType<
    $PropertyType<PolicyViolationsListQueryVersion2Query, 'policy_violations_list_v2'>,
    'policy_violations',
  >,
  0,
>;

type CloudResourcesType = $ElementType<
  $PropertyType<$PropertyType<CloudResourcesListQuery, 'cloud_resource_list'>, 'cloud_resources'>,
  0,
>;

type ApiOperationType = $ElementType<
  $PropertyType<$PropertyType<ApiOperationsListQueryQuery, 'api_operation_list'>, 'api_operations'>,
  0,
>;

type ScanType = {
  current_status: SpecialScanStatus,
  date_created: Date,
  ...
};

type Props = {
  policyViolations: $ReadOnlyArray<PolicyViolationType> | null,
  assets: $ReadOnlyArray<CloudResourcesType> | $ReadOnlyArray<ApiOperationType> | null,
  scans: $ReadOnlyArray<ScanType>,
  policyViolationPath: string,
  portalPath: string,
  assetType: AssetTypeEnum,
  onClick: (PolicyViolationType | CloudResourcesType | ApiOperationType) => void,
  fetchMore: null | (() => mixed),
  openRowId: ?string,
  isV2?: boolean,
};

function ToolkitAssetTable({
  policyViolations,
  assets,
  assetType,
  scans,
  policyViolationPath,
  portalPath,
  onClick,
  openRowId,
  fetchMore,
  isV2 = false,
}: Props) {
  const classes = useStyles({});

  return (
    <TableContainer classes={{ root: classes.tableContainer }}>
      <Table stickyHeader>
        <CloudLeaksTableHead />
        <TableBody>
          {policyViolations?.map((policyViolation, idx) => (
            <ToolkitTablePolicyViolationRow
              key={idx}
              policyViolationPath={policyViolationPath}
              portalPath={portalPath}
              asset={policyViolation}
              scans={scans}
              onClick={onClick}
              openRowId={openRowId}
            />
          ))}
          {assets?.map((asset, idx) => (
            <ToolkitTableAssetRow
              key={idx}
              isV2={isV2}
              portalPath={portalPath}
              asset={asset}
              assetType={assetType}
              openRowId={openRowId}
              onClick={onClick}
              scans={scans}
            />
          ))}
        </TableBody>
      </Table>
      {fetchMore && (
        <Waypoint onEnter={() => fetchMore()}>
          <Grid container classes={{ root: classes.fetchMoreLoading }}>
            <Grid container alignItems="center" justifyContent="center">
              <CircularProgress color="primary" size={24} />
            </Grid>
          </Grid>
        </Waypoint>
      )}
    </TableContainer>
  );
}

export default React.memo<Props>(ToolkitAssetTable);
