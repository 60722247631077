//@flow
import { createAction, type ActionType } from 'redux-actions';
import { type NotificationPreferences } from '@dt/user-api/current_user';

export type PreferencesState = {
  notification: null | NotificationPreferences,
  ...
};

export const setNotificationPreferences = createAction<
  'SET_PREFERENCES_NOTIFICATION',
  [NotificationPreferences],
  NotificationPreferences,
>('SET_PREFERENCES_NOTIFICATION', (notification_preferences: NotificationPreferences) => notification_preferences);

export default (
  state: PreferencesState = { notification: null },
  action: ActionType<typeof setNotificationPreferences>,
) => {
  if (action.type === setNotificationPreferences.toString()) {
    return { ...state, notification: action.payload };
  }
  return state;
};
