//@flow
import React, { memo, type Node } from 'react';
import { Grid, Typography } from '@mui/material';
import { palette } from '@dt/theme';

type Props = {|
  +children?: ?Node,
  +urgent?: ?number,
  +important?: ?number,
  +proactive?: ?number,
  +resolved?: ?number,
|};

export default memo<Props>(function HostPieChartTooltip({ children, urgent, important, proactive, resolved }) {
  return (
    <TooltipContainer>
      {typeof urgent === 'number' && <TooltipSwatchRow color={palette.red} value={urgent} label="High Severity" />}
      {typeof important === 'number' && (
        <TooltipSwatchRow color={palette.yellow} value={important} label="Medium Severity" />
      )}
      {typeof proactive === 'number' && (
        <TooltipSwatchRow color={palette.blue} value={proactive} label="Low Severity" />
      )}
      {typeof resolved === 'number' && <TooltipSwatchRow color={palette.green} value={resolved} label="no Security" />}
      {children}
    </TooltipContainer>
  );
});

function TooltipContainer({ children }) {
  return (
    <div
      style={{
        borderRadius: 5,
        border: `1px solid ${palette.gray30}`,
        backgroundColor: palette.white,
        padding: 10,
      }}
    >
      {children}
    </div>
  );
}

function TooltipSwatch({ color }) {
  return (
    <div
      style={{
        width: 24,
        height: 24,
        backgroundColor: color,
        borderRadius: 3,
        marginRight: 5,
      }}
    />
  );
}

function TooltipSwatchRow({ color, label, value }) {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <TooltipSwatch color={color} />
      </Grid>
      <Grid item>
        <Typography display="inline" variant="h4" style={{ marginRight: 5 }}>
          {value}
        </Typography>{' '}
        <Typography variant="body2" display="inline">
          Asset{value !== 1 && 's'} with {label} Issues
        </Typography>
      </Grid>
    </Grid>
  );
}
