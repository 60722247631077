//@flow
import React, { memo, type Node } from 'react';
import { makeStyles } from '@mui/styles';

import { palette } from '@dt/theme';

const useStyles = makeStyles({
  container: {
    // region fill
    width: ({ fill }: Props) => (fill ? '100%' : ''),
    height: ({ fill }: Props) => (fill ? '100%' : ''),
    // endregion fill

    display: 'flex',
  },

  badge: {
    // region fill
    width: ({ fill }: Props) => (fill ? '100%' : ''),
    height: ({ fill }: Props) => (fill ? '100%' : ''),
    // endregion fill

    backgroundColor: ({ backgroundColor }: Props) => backgroundColor || palette.brand,

    // region borderRadius
    borderTopLeftRadius: ({ radius }: Props) => radius,
    borderBottomLeftRadius: ({ radius }: Props) => radius,
    borderTopRightRadius: ({ radius }: Props) => radius,
    borderBottomRightRadius: ({ radius }: Props) => radius,
    // endregion borderRadius

    color: ({ color }: Props) => color || '#fff',
    // region size
    fontSize: ({ size }: Props) => (size === 'large' ? '11px' : '9px'),
    fontWeight: 'bold',
    lineHeight: ({ size }: Props) => (size === 'large' ? '11px' : '9px'),
    padding: ({ size }: Props) => (size === 'large' ? '3px 6px 3px 6px' : '2px 4px 2px 4px'),
    // endregion size

    // region uppercase
    textTransform: 'uppercase',
    // endregion uppercase

    whiteSpace: 'nowrap',
  },
});

type Props = {|
  backgroundColor?: string,
  color?: string,
  radius?: number,

  fill?: boolean,
  size?: 'large' | 'small',
  label: Node,
|};

function Badge(props) {
  const { label } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <div className={classes.badge}>{label}</div>
    </div>
  );
}

Badge.defaultProps = {
  fill: false,
  size: 'large',
  radius: 3,
};
export default memo<Props>(Badge);
