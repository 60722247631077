//@flow
import React, { memo } from 'react';
import LanguageIcon from '@mui/icons-material/Language';

import Tag from './Tag';

type Props = {
  +url: string,
};

function TagNetworkServiceUrl({ url }: Props) {
  return <Tag label={url} icon={<LanguageIcon style={{ width: 14, height: 14 }} />} rounded />;
}
export default memo<Props>(TagNetworkServiceUrl);
