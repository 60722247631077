// @flow
import React, { memo } from 'react';
import { Button } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { shared_links } from '@dt/horizon-api';
import Banner from './Banner';
import { SharedLinksButtonCommon } from './SharedLinksButtonCommon';

type Props =
  | {| +web_application_id: string |}
  | {| +restful_api_id: string |}
  | {| +network_service_id: string |}
  | {| +cloud_resource_id: string |}
  | {| +graphql_api_id: string |};

/*
 * NOTE: Use the `SharedLinksButton` facade component.
 *
 * Shared links button used to share horizon assets.
 *
 * @param web_application_id - Used with the api when creating a shared link
 * @param restful_api_id - Used with the api when creating a shared link
 * @param network_service_id - Used with the api when creating a shared link
 * @param cloud_resource_id - Used with the api when creating a shared link
 * @param graphql_api_id - Used with the api when creating a shared link
 *
 * @example
 *     <SharedLinksButtonMobileApplication mobile_application_id={mobile_application.id} />
 */
const SharedLinksButtonAssetComponent = function SharedLinksButtonAsset(props: Props) {
  /* Figure out the type of shared link we will need to create if the user completes the flow */
  let assetId: string;
  let functionToCreateSharedLink;
  let assetPathInUrl: string;

  if (props.web_application_id) {
    assetId = props.web_application_id;
    functionToCreateSharedLink = shared_links.create_for_web_application_id;
    assetPathInUrl = 'web-applications';
  } else if (props.restful_api_id) {
    assetId = props.restful_api_id;
    functionToCreateSharedLink = shared_links.create_for_restful_api_id;
    assetPathInUrl = 'restful-apis';
  } else if (props.network_service_id) {
    assetId = props.network_service_id;
    functionToCreateSharedLink = shared_links.create_for_network_service_id;
    assetPathInUrl = 'network-services';
  } else if (props.cloud_resource_id) {
    assetId = props.cloud_resource_id;
    functionToCreateSharedLink = shared_links.create_for_cloud_resource_id;
    assetPathInUrl = 'cloud-resources';
  } else if (props.graphql_api_id) {
    assetId = props.graphql_api_id;
    functionToCreateSharedLink = shared_links.create_for_graphql_api_id;
    assetPathInUrl = 'graphql-apis';
  } else {
    throw new Error('Tried to render the SharedLinksButtonAsset component but no asset ID was supplied');
  }

  /*
   * Handle creating a shared link for asset types.
   */
  const handleOnCreate = async validity_duration => {
    // Create shared link.
    const response = await functionToCreateSharedLink(assetId, {
      validity_duration,
      allows_access_to_related_policy_violations: true,
    });

    // Error occurred, let shared links button render an error.
    if (response._type === 'error') {
      throw new Error(response.title);
    }

    // Success, let shared links button render page url.
    return `${window.location.origin}/api/share/${response.body.access_token}/${assetPathInUrl}/${assetId}`;
  };

  return (
    <SharedLinksButtonCommon
      manageShareLink="/api/shared-links"
      title="Create a secure link"
      onCreate={handleOnCreate}
      renderButton={(toggleDialog, disabled) => (
        <Button variant="contained" startIcon={<ShareIcon />} onClick={toggleDialog} disabled={disabled}>
          Secure Share
        </Button>
      )}
    >
      <Banner status="warning" inline icon={<></>}>
        The secure link will allow temporary access to this asset&apos;s details and policy violations.
      </Banner>
    </SharedLinksButtonCommon>
  );
};

export const SharedLinksButtonAsset = memo<Props>(SharedLinksButtonAssetComponent);
