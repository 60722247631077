//@flow
import React, { memo } from 'react';

import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useStyles } from './NotificationItem';

export default memo<{||}>(function NotificationItemLoadingSkeleton() {
  const styles = useStyles();

  return (
    <Grid container direction="row" className={styles.mainContainer} spacing={2}>
      <Grid item xs={1}>
        <Skeleton variant="circular" animation="wave" width={32} height={32} />
      </Grid>
      <Grid item container direction="column" xs={11}>
        <Grid item className={styles.title}>
          <Skeleton animation="wave" width={365} />
        </Grid>
        <Grid item>
          <Skeleton animation="wave" width={365} />
        </Grid>
        <Grid
          item
          container
          direction="row"
          spacing={2}
          alignContent="center"
          alignItems="center"
          className={styles.title}
        >
          <Grid item>
            <Skeleton variant="circular" animation="wave" width={16} height={16} />
          </Grid>
          <Grid item>
            <Skeleton animation="wave" width={333} />
          </Grid>
        </Grid>
        <Grid item>
          <Skeleton animation="wave" width={135} />
        </Grid>
      </Grid>
    </Grid>
  );
});
