var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Box from '@mui/material/Box';
var PageContainer = function (_a) {
    var _b = _a.fullWidth, fullWidth = _b === void 0 ? true : _b, children = _a.children, _c = _a.large, large = _c === void 0 ? false : _c;
    return (React.createElement(Box, { style: __assign({ backgroundColor: '#F4F5FC', padding: '0 24px', minHeight: '100vh', margin: '0 auto' }, (fullWidth ? {} : { maxWidth: large ? 1200 : 900 })) }, children));
};
export default PageContainer;
