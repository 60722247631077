//@flow
import React, { useState, type Node } from 'react';
import { makeStyles } from '@mui/styles';

import { palette } from '@dt/theme';
import { type ShadowMetadataCountType } from '@dt/user-api/mobile_apps';

import PriorityBadge from './../PriorityBadge';
import { CardWithStatusIndicator } from '@dt/material-components';

import { getShadowScanTitle, getMetadataOr } from './Categories';
import InquireButton from './InquireButton';
import PreviewButton from './PreviewButton';
import PreviewDialog from './PreviewDialog';

const useStyles = makeStyles({
  container: {
    paddingTop: 10,
    paddingBottom: 5,
    paddingLeft: 55,
    paddingRight: 10,
  },

  header: {
    color: palette.faded,
    display: 'flex',
    justifyContent: 'space-between',
  },

  content: {
    paddingTop: 10,
  },
});

type DefaultProps = {|
  type: void | string, // TODO: 'enum'.
  // TODO: REMOVE overrideCount - shadow scan cases should be handled here.
  //       See MobileApplicationIssues for the use case that requires this atm.
  overrideCount?: ?number,
|};

type Props = {|
  ...DefaultProps,
  // TODO: should not be optional - make optional when overrideCount is removed.
  shadowMetadataCount?: ?ShadowMetadataCountType,

  onClickInquire: (params: {|
    title?: ?string,
    count?: ?number,
    breakdown?: ?ShadowMetadataCountType,
  |}) => mixed,
  children?: ?Node,
|};

function ShadowScanSummaryCard(props) {
  const { children, type, shadowMetadataCount, overrideCount, onClickInquire } = props;

  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const title = getShadowScanTitle(type);
  const description = getMetadataOr(type, 'description');
  const categoryType = getMetadataOr(type, 'categoryType', 'ALL');
  const breakdownType = getMetadataOr(type, 'breakdownType');
  const count =
    overrideCount !== null || !shadowMetadataCount ? overrideCount : shadowMetadataCount[categoryType || 'ALL'];

  return (
    <CardWithStatusIndicator status={`5px solid ${palette.brand}`}>
      <div className={classes.container}>
        {/* Header */}
        <div className={classes.header}>
          {/* Left */}
          <div>
            <strong>
              <PriorityBadge type={count} />
              <span style={{ marginLeft: 10 }}>{title}</span>
            </strong>
          </div>

          {/* Right */}
          <div>
            {children ? (
              <PreviewButton onPreviewClick={() => setOpen(true)} />
            ) : (
              <InquireButton
                onClickInquire={() => {
                  onClickInquire({
                    title: title,
                    count: count,
                    breakdown: shadowMetadataCount,
                  });
                }}
              />
            )}
          </div>
        </div>

        {/* Content */}
        <div className={classes.content}>
          {description}
          {(breakdownType === 'FULL' || breakdownType === 'PARTIAL') && shadowMetadataCount && (
            <ul>
              <li>{shadowMetadataCount.P1} Security P1 Issues</li>
              <li>{shadowMetadataCount.APPLE_BLOCKER} App Store Blockers</li>
              <li>{shadowMetadataCount.GOOGLE_BLOCKER} Google Play Blockers</li>
              <li>{shadowMetadataCount.COMPLIANCE} Regulatory Compliance</li>
              {breakdownType === 'FULL' && <li>{shadowMetadataCount.HIGH} High Severity</li>}
              {breakdownType === 'FULL' && <li>{shadowMetadataCount.MEDIUM} Medium Severity</li>}
              {breakdownType === 'FULL' && <li>{shadowMetadataCount.LOW} Low Severity</li>}
            </ul>
          )}

          <PreviewDialog
            open={open}
            onClickInquire={() => {
              onClickInquire({
                title: title,
                count: count,
                breakdown: shadowMetadataCount,
              });
            }}
            onRequestClose={() => setOpen(false)}
          >
            {children}
          </PreviewDialog>
        </div>
      </div>
    </CardWithStatusIndicator>
  );
}

ShadowScanSummaryCard.defaultProps = {
  type: 'all',
  overrideCount: null,
};

export const shouldShowFor = (type: string, shadowMetadataCount: ?ShadowMetadataCountType): boolean => {
  // If we weren't provided any shadow scan metadata.
  if (!shadowMetadataCount) return false;

  const categoryType = getMetadataOr(type, 'categoryType', 'ALL');
  const count = shadowMetadataCount[categoryType || 'ALL'];

  // If we don't have any shadow scan findings for this category.
  if (!count) return false;

  return true;
};

export default React.memo<Props>(ShadowScanSummaryCard);
