// @flow
import React, { memo } from 'react';
import { Grid, Button } from '@mui/material';
import Box from '@mui/material/Box';
import { palette } from '@dt/theme';

type Props = {
  message: string,
  onConfirm: () => void,
  onCancel: () => void,
};

const ConfirmMessageComponent = function ConfirmMessage({ message, onConfirm, onCancel }: Props) {
  return (
    <Box
      bgcolor={palette.yellow50}
      color={palette.gray20}
      p={2}
      borderRadius={2}
      whiteSpace="pre-line" // Used to allow messages to be multiline strings.
    >
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={12}>
          {message}
        </Grid>
        <Grid item xs={12} md={2}>
          <Button variant="contained" onClick={onConfirm}>
            Confirm
          </Button>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button variant="contained" onClick={onCancel}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo<Props>(ConfirmMessageComponent);
