//@flow
import React, { memo, useState, useMemo } from 'react';

import { Drawer, Grid, Tooltip } from '@mui/material';
import Text from '../Text';
import NotificationItem from './NotificationItem';
import NotificationItemZeroState from './NotificationItemZeroState';
import NotificationItemError from './NotificationItemError';
import NotificationItemLoadingSkeleton from './NotificationItemLoadingSkeleton';

import horizon_notifications from '@dt/graphql-support/horizon/notification_events';
import sevenhell_notifications from '@dt/graphql-support/sevenhell/portal_notifications';
import { useLazyQuery as useLazyPaginatedQuery } from '@dt/apollo-link-schema-rest';
import type { NotificationEventsListQuery, PortalNotificationsListQuery } from '@dt/graphql-support/types';
import { Waypoint } from 'react-waypoint';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/difference_in_days';
import { palette } from '@dt/theme';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { makeStyles } from '@mui/styles';

type Props = {|
  isSidebarExpanded: boolean,
|};

export const useStyle = makeStyles({
  menuOuter: {
    alignItems: 'center',
    borderRadius: 4,
    color: palette.white,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 8,
    marginLeft: 16,
    marginRight: 16,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    '&:hover': {
      backgroundColor: palette.brand20,
    },

    '&.active': {
      backgroundColor: palette.brand35,
    },

    '&.bottom': {
      marginTop: 'auto',
    },
  },

  menuInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  menuIconBox: {
    maxWidth: 40,
    maxHeight: 40,
    minWidth: 40,
    minHeight: 40,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px',
  },

  menuText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&.title': {
      color: palette.white,
      fontSize: 20,
    },
  },
});

export default memo<Props>(function NotificationsDrawer({ isSidebarExpanded }: Props) {
  const css = useStyle();

  const [
    fetchHorizonNotifications,
    {
      called: horizonNotificationsCalled,
      data: horizonNotificationsData,
      loading: horizonNotificationsLoading,
      error: horizonNotificationsError,
      fetchMore: horizonNotificationsFetchMore,
    },
  ] = useLazyPaginatedQuery<NotificationEventsListQuery, _>(horizon_notifications.list);

  const [
    fetchSevenhellNotifications,
    {
      called: sevenhellNotificationsCalled,
      data: sevenhellNotificationsData,
      loading: sevenhellNotificationsLoading,
      error: sevenhellNotificationsError,
      fetchMore: sevenhellNotificationsFetchMore,
    },
  ] = useLazyPaginatedQuery<PortalNotificationsListQuery, _>(sevenhell_notifications.list);

  const [anchorEl, setanchorEl] = useState(null);
  const handleOpen = event => {
    setanchorEl(event.currentTarget);
    !horizonNotificationsCalled && fetchHorizonNotifications();
    !sevenhellNotificationsCalled && fetchSevenhellNotifications();
  };
  const handleClose = () => setanchorEl(null);

  const styles = {
    notificationsContainer: {
      paddingLeft: isSidebarExpanded ? 256 : 72,
    },
  };

  const horizonNotifications = horizonNotificationsData?.notification_events_list?.events
    ? horizonNotificationsData.notification_events_list.events
    : [];

  const sevenhellNotifications = sevenhellNotificationsData?.portal_notifications_list?.portal_notifications
    ? sevenhellNotificationsData.portal_notifications_list.portal_notifications
    : [];

  const notificationsLoading = sevenhellNotificationsLoading || horizonNotificationsLoading;

  const notificationsError = sevenhellNotificationsError || horizonNotificationsError;

  let currentDate = new Date(Date.now());
  let hasTodayBeenChecked = false;

  if (horizonNotificationsError) {
    console.error('horizonNotificationsError:', horizonNotificationsError);
  }
  if (sevenhellNotificationsError) {
    console.error('sevenhellNotificationsError:', sevenhellNotificationsError);
  }

  const notifications = useMemo(
    () =>
      [...horizonNotifications, ...sevenhellNotifications].sort((a, b) => {
        if (a.date_created && b.date_created) {
          if (a.date_created > b.date_created) {
            return -1;
          }
          return 1;
        }
        return 0;
      }),
    [horizonNotifications, sevenhellNotifications],
  );

  return (
    <>
      <div
        className={css.menuOuter}
        type="button"
        onClick={event => {
          anchorEl ? handleClose() : handleOpen(event);
        }}
        aria-haspopup="true"
        aria-label="Notifications Drawer"
      >
        <Tooltip disableInteractive title="Notifications">
          <div className={css.menuInner}>
            <div className={css.menuIconBox} label="notifications-menu-icon">
              <NotificationsIcon color="inherit" />
            </div>
            <div className={css.menuText}>Notifications</div>
          </div>
        </Tooltip>
      </div>
      <Drawer
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ zIndex: 1100 }}
        PaperProps={{
          style: {
            width: 488 + (isSidebarExpanded ? 256 : 72),
            overflowX: 'hidden',
          },
        }}
        aria-labelledby={'Notifications Drawer'}
      >
        <Grid
          container
          direction="column"
          spacing={2}
          alignContent="center"
          alignItems="flex-start"
          style={styles.notificationsContainer}
        >
          <Grid item>
            <Text variant="titleS" style={{ marginBottom: '8px' }}>
              Notifications
            </Text>
          </Grid>
          {!notifications && !notificationsLoading && !notificationsError && <NotificationItemZeroState />}
          {notifications
            ? notifications.map(notification => {
                let dateHeader = null;

                if (differenceInDays(Date.now(), notification.date_created) === 0 && !hasTodayBeenChecked) {
                  dateHeader = 'Today';
                  hasTodayBeenChecked = true;
                }
                if (differenceInDays(currentDate, notification.date_created) > 0) {
                  dateHeader = format(notification.date_created, 'MMM DD YYYY');
                  currentDate = notification.date_created;
                }
                return (
                  <Grid key={notification.id} item>
                    {Boolean(dateHeader) && (
                      <Text variant="titleXS" style={{ margin: 0, color: palette.gray35 }}>
                        {dateHeader}
                      </Text>
                    )}
                    <NotificationItem notification={notification} />
                  </Grid>
                );
              })
            : null}
          {notificationsLoading &&
            [0, 1, 2, 3, 4, 5, 6].map(i => {
              return (
                <Grid key={i} item>
                  <NotificationItemLoadingSkeleton />
                </Grid>
              );
            })}
          {!sevenhellNotificationsLoading &&
            sevenhellNotificationsData?.portal_notifications_list?.pagination_information?.next_cursor && (
              <Grid item>
                <Waypoint
                  onEnter={() => {
                    sevenhellNotificationsFetchMore && sevenhellNotificationsFetchMore();
                  }}
                />
              </Grid>
            )}
          {!horizonNotificationsLoading &&
            horizonNotificationsData?.notification_events_list?.pagination_information?.next_cursor && (
              <Grid item>
                <Waypoint
                  onEnter={() => {
                    horizonNotificationsFetchMore && horizonNotificationsFetchMore();
                  }}
                />
              </Grid>
            )}
          {(horizonNotificationsError || sevenhellNotificationsError) && <NotificationItemError />}
        </Grid>
      </Drawer>
    </>
  );
});
