// @flow
import React, { memo, useState } from 'react';
import { GlobalSettingsMenu } from '../index';
import { Tooltip } from '@mui/material';
import { palette } from '@dt/theme';
import { makeStyles } from '@mui/styles';
import SettingsIcon from '@mui/icons-material/Settings';

let useStyle = makeStyles({
  menuOuter: {
    alignItems: 'center',
    borderRadius: 4,
    color: palette.white,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 8,
    marginLeft: 16,
    marginRight: 16,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    '&:hover': {
      backgroundColor: palette.brand20,
    },

    '&.active': {
      backgroundColor: palette.brand35,
    },

    '&.bottom': {
      marginTop: 'auto',
    },
  },

  menuInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  menuIconBox: {
    maxWidth: 40,
    maxHeight: 40,
    minWidth: 40,
    minHeight: 40,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px',
  },

  menuText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&.title': {
      color: palette.white,
      fontSize: 20,
    },
  },
});

function ConfigurableLHSMenuSettings() {
  let css = useStyle();

  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const handleOpenSettings = event => setSettingsAnchorEl(event.currentTarget);
  const handleCloseSettings = () => setSettingsAnchorEl(null);

  return (
    <>
      <div
        className={css.menuOuter}
        type="button"
        onClick={event => handleOpenSettings(event)}
        aria-controls="settings-menu"
        aria-haspopup="true"
        aria-label="Settings Menu"
      >
        <Tooltip disableInteractive title="Settings">
          <div className={css.menuInner}>
            <div className={css.menuIconBox}>
              <SettingsIcon color="inherit" />
            </div>
            <div className={css.menuText}>Settings</div>
          </div>
        </Tooltip>
      </div>
      <GlobalSettingsMenu anchorEl={settingsAnchorEl} handleClose={handleCloseSettings} />
    </>
  );
}

export default memo<{||}>(ConfigurableLHSMenuSettings);
