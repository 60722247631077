// @flow
import { flow, assoc, dissoc, toPath } from 'lodash/fp';
import { ActionEnum } from '../actions/filterActions';
import { getCustomRange } from '../util/filtersUtil';
import { default as CompliancePolicyEnumValues } from '@dt/enums/CompliancePolicyEnum';
import type { Reducer } from 'redux';
import type { FindingPriorityEnum } from '@dt/enums/FindingPriorityEnum';
import type { SecurityTemplateSeverityEnum } from '@dt/enums/SecurityTemplateSeverityEnum';
import type { CompliancePolicyEnum } from '@dt/enums/CompliancePolicyEnum';
import type { FindingTargetStatusEnum } from '@dt/enums/FindingTargetStatusEnum';
import type { ReleaseType } from '@dt/enums/MobileAppReleaseTypeEnum';
import type { SecurityTemplateImportanceTagStoreBlockersEnum } from '@dt/enums/SecurityTemplateImportanceTagEnum';
import type { DateRange, MinAge, FilterActions, SavedFilters } from '../actions/filterActions';
import type { FindingSortChoiceEnum } from '@dt/user-api/search_queries';

export type FilterStore = {|
  findingsCriteria: {|
    +id: ?string,
    +description: string,
    +isOwner: boolean,
    +isPrivate: boolean,
    // Some filters are disabled for loading up because the UI doesn't support selectedStatuses.
    +isDisabledLoading: boolean,
    +selectedKeywords: ?string,
    +selectedMobileAppIds: string[],
    +selectedPriorities: $ReadOnlyArray<FindingPriorityEnum>,
    +selectedStoreBlockers: $ReadOnlyArray<SecurityTemplateImportanceTagStoreBlockersEnum>,
    +selectedSeverities: $ReadOnlyArray<SecurityTemplateSeverityEnum>,
    +selectedCompliancePolicies: $ReadOnlyArray<CompliancePolicyEnum>,
    +selectedStatuses: {|
      +statuses: $ReadOnlyArray<FindingTargetStatusEnum>,
      +dateRange: DateRange,
    |},
    +selectedCurrentStatuses: $ReadOnlyArray<FindingTargetStatusEnum>,
    +selectedReleaseTypes: $ReadOnlyArray<ReleaseType>,
    +minAge: MinAge,
    +sortBy: FindingSortChoiceEnum | '',
  |},
  +savedFilters: SavedFilters,
  +open: boolean,
|};

export const initialState: FilterStore = {
  findingsCriteria: {
    id: null,
    description: '',
    isOwner: false,
    isPrivate: false,
    isDisabledLoading: false,
    selectedKeywords: '',
    selectedPriorities: [],
    selectedMobileAppIds: [],
    selectedStoreBlockers: [],
    selectedSeverities: [],
    selectedCompliancePolicies: [],
    selectedStatuses: {
      statuses: [],
      dateRange: { type: 'ANY_TIME' },
    },
    selectedCurrentStatuses: [],
    selectedReleaseTypes: [],
    minAge: '',
    sortBy: '',
  },
  savedFilters: {
    '1520282902332': {
      name: 'P1 Issues/App & Play Store Blockers over "Last 90" days',
      value: {
        id: '1520282902332',
        description: '',
        isOwner: false,
        isPrivate: true,
        isDisabledLoading: true,
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: ['P1'],
        selectedStoreBlockers: [],
        selectedSeverities: [],
        selectedCompliancePolicies: [],
        selectedStatuses: {
          statuses: [],
          dateRange: {
            type: 'LAST_90_DAYS',
          },
        },
        selectedCurrentStatuses: [],
        selectedReleaseTypes: [],
        minAge: '',
        sortBy: '',
      },
    },
    '1520282960256': {
      name: 'Closed vulnerabilities over "Last 90" days',
      value: {
        id: '1520282960256',
        description: '',
        isOwner: false,
        isPrivate: true,
        isDisabledLoading: true,
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: [],
        selectedStoreBlockers: [],
        selectedSeverities: [],
        selectedCompliancePolicies: [],
        selectedStatuses: {
          statuses: ['CLOSED_FIXED', 'CLOSED_ITEM_NOT_FOUND'],
          dateRange: {
            type: 'LAST_90_DAYS',
          },
        },
        selectedCurrentStatuses: [],
        selectedReleaseTypes: [],
        minAge: '',
        sortBy: '',
      },
    },
    '1520283108612': {
      name: 'Vulnerabilities exceeding 90-day aging over "Last 90" days',
      value: {
        id: '1520283108612',
        description: '',
        isOwner: false,
        isPrivate: true,
        isDisabledLoading: true,
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: [],
        selectedStoreBlockers: [],
        selectedSeverities: [],
        selectedCompliancePolicies: [],
        selectedStatuses: {
          statuses: [],
          dateRange: {
            type: 'LAST_90_DAYS',
          },
        },
        selectedCurrentStatuses: ['NEW', 'OPEN'],
        selectedReleaseTypes: [],
        minAge: 90,
        sortBy: '',
      },
    },
    '1520283382146': {
      name: 'Top *New* vulnerabilities found over "Last 90" days',
      value: {
        id: '1520283382146',
        description: '',
        isOwner: false,
        isPrivate: true,
        isDisabledLoading: true,
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: [],
        selectedStoreBlockers: [],
        selectedSeverities: [],
        selectedCompliancePolicies: [],
        selectedStatuses: {
          statuses: ['NEW'],
          dateRange: {
            type: 'LAST_90_DAYS',
          },
        },
        selectedCurrentStatuses: [],
        selectedReleaseTypes: [],
        minAge: '',
        sortBy: '',
      },
    },
    '1519929559762': {
      name: 'The most critical flaws that didn’t make it into production',
      value: {
        id: '1519929559762',
        description: '',
        isOwner: false,
        isPrivate: true,
        isDisabledLoading: false,
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: ['P1'],
        selectedSeverities: [],
        selectedCompliancePolicies: [],
        selectedStoreBlockers: [],
        selectedStatuses: {
          statuses: [],
          dateRange: { type: 'ANY_TIME' },
        },
        selectedCurrentStatuses: [],
        selectedReleaseTypes: ['PRE_PROD'],
        minAge: '',
        sortBy: '',
      },
    },
    '1534050811968': {
      name: 'P1 Issues Marked as Risk Accepted/CC',
      value: {
        id: '1534050811968',
        description: '',
        isOwner: false,
        isPrivate: true,
        isDisabledLoading: false,
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: ['P1'],
        selectedStoreBlockers: [],
        selectedSeverities: [],
        selectedCompliancePolicies: [],
        selectedStatuses: {
          statuses: [],
          dateRange: {
            type: 'ANY_TIME',
          },
        },
        selectedCurrentStatuses: ['CLOSED_RISK_ACCEPTED', 'CLOSED_COMPENSATING_CONTROL'],
        selectedReleaseTypes: [],
        minAge: '',
        sortBy: '',
      },
    },
    '1534050910267': {
      name: 'Open Regulatory Compliance Issues',
      value: {
        id: '1534050910267',
        description: '',
        isOwner: false,
        isPrivate: true,
        isDisabledLoading: false,
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: [],
        selectedStoreBlockers: [],
        selectedSeverities: [],
        selectedCompliancePolicies: Object.keys(CompliancePolicyEnumValues),
        selectedStatuses: {
          statuses: [],
          dateRange: {
            type: 'ANY_TIME',
          },
        },
        selectedCurrentStatuses: ['OPEN', 'NEW'],
        selectedReleaseTypes: [],
        minAge: '',
        sortBy: '',
      },
    },
    '1534050948893': {
      name: 'Regulatory Compliance Issues Marked as Risk Accepted/CC',
      value: {
        id: '1534050948893',
        description: '',
        isOwner: false,
        isPrivate: true,
        isDisabledLoading: false,
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: [],
        selectedStoreBlockers: [],
        selectedSeverities: [],
        selectedCompliancePolicies: Object.keys(CompliancePolicyEnumValues),
        selectedStatuses: {
          statuses: [],
          dateRange: {
            type: 'ANY_TIME',
          },
        },
        selectedCurrentStatuses: ['CLOSED_RISK_ACCEPTED', 'CLOSED_COMPENSATING_CONTROL'],
        selectedReleaseTypes: [],
        minAge: '',
        sortBy: '',
      },
    },
    '1534050984757': {
      name: 'Total Closed Issues',
      value: {
        id: '1534050984757',
        description: '',
        isOwner: false,
        isPrivate: true,
        isDisabledLoading: false,
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: [],
        selectedStoreBlockers: [],
        selectedSeverities: [],
        selectedCompliancePolicies: [],
        selectedStatuses: {
          statuses: [],
          dateRange: {
            type: 'ANY_TIME',
          },
        },
        selectedCurrentStatuses: ['CLOSED_FIXED', 'CLOSED_ITEM_NOT_FOUND'],
        selectedReleaseTypes: [],
        minAge: '',
        sortBy: '',
      },
    },
  },
  open: false,
};

const statusPath = toPath(['findingsCriteria', 'selectedStatuses']);

const reducer: Reducer<FilterStore, FilterActions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionEnum.SET_KEYWORDS_FILTER:
      return assoc(['findingsCriteria', 'selectedKeywords'], action.payload, state);
    case ActionEnum.SET_MOBILE_APP_IDS_FILTER:
      return assoc(['findingsCriteria', 'selectedMobileAppIds'], action.payload, state);
    case ActionEnum.SET_PRIORITY_FILTER:
      return assoc(['findingsCriteria', 'selectedPriorities'], action.payload, state);
    case ActionEnum.SET_STORE_BLOCKERS_FILTER:
      return assoc(['findingsCriteria', 'selectedStoreBlockers'], action.payload, state);
    case ActionEnum.SET_SEVERITY_FILTER:
      return assoc(['findingsCriteria', 'selectedSeverities'], action.payload, state);
    case ActionEnum.SET_COMPLIANCE_POLICY_FILTER:
      return assoc(['findingsCriteria', 'selectedCompliancePolicies'], action.payload, state);
    case ActionEnum.SET_STATUS_FILTER:
      return assoc([...statusPath, 'statuses'], action.payload, state);
    case ActionEnum.SET_DATE_FILTER:
      return action.payload === 'CUSTOM'
        ? assoc([...statusPath, 'dateRange'], getCustomRange(), state)
        : assoc([...statusPath, 'dateRange', 'type'], action.payload, state);
    case ActionEnum.SET_DATE_FROM_FILTER:
      return assoc([...statusPath, 'dateRange', 'from'], action.payload, state);
    case ActionEnum.SET_DATE_TO_FILTER:
      return assoc([...statusPath, 'dateRange', 'to'], action.payload, state);
    case ActionEnum.SET_CURRENT_STATUS_FILTER:
      return assoc(['findingsCriteria', 'selectedCurrentStatuses'], action.payload, state);
    case ActionEnum.SET_RELEASE_TYPE_FILTER:
      return assoc(['findingsCriteria', 'selectedReleaseTypes'], action.payload, state);
    case ActionEnum.SET_MIN_AGE_FILTER:
      return assoc(['findingsCriteria', 'minAge'], action.payload, state);
    case ActionEnum.SET_FINDINGS_SORT:
      return assoc(['findingsCriteria', 'sortBy'], action.payload, state);
    case ActionEnum.TOGGLE_SHOWING:
      return assoc(['showing'], action.payload, state);
    case ActionEnum.ADD_FILTER:
      return state;
    case ActionEnum.ADDED_FILTER:
      if (!action.payload.value.id) {
        return state;
      }

      return assoc(['savedFilters', action.payload.value.id], action.payload, state);
    case ActionEnum.REMOVE_FILTER:
      return state;
    case ActionEnum.REMOVED_FILTER:
      return dissoc(['savedFilters', action.payload], state);
    case ActionEnum.SET_AS_CURRENT_FILTER:
      return assoc(['findingsCriteria'], action.payload, state);
    case ActionEnum.RESET_FILTER:
      return assoc(['findingsCriteria'], initialState.findingsCriteria, state);
    case ActionEnum.FILTERS_LOADED_FROM_SERVER:
      return assoc(['savedFilters'], { ...action.payload, ...state.savedFilters }, state);
    case ActionEnum.OPEN_BUTTON_CLICKED:
      return assoc(['open'], !state.open, state);
    case ActionEnum.REQUEST_FILTERS_FROM_SERVER:
      return state;
    case ActionEnum.SET_GLOBAL_FILTER:
      return flow(assoc(['savedFilters', action.payload.id, 'value', 'isPrivate'], action.payload.value))(state);

    default:
      (action: empty);
      return state;
  }
};

export default reducer;
