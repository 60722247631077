// @flow
import React, { memo } from 'react';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import LabelIcon from '@mui/icons-material/Label';
import IconButton from '@mui/material/IconButton';
import AssetTagsEditDialogAssetTagRowKey from './AssetTagsEditDialogAssetTagRowKey';
import AssetTagsEditDialogAssetTagRowValue from './AssetTagsEditDialogAssetTagRowValue';
import type { AssetTag as AssetTagType } from '@dt/graphql-support/types';

type AssetTag = { ...$Diff<AssetTagType, { __typename?: ?string }> };

type Props = {
  assetTag: AssetTag,
  validation: void | { tag: string, value?: ?string, message: string },
  onUpdate: (oldAssetTag: AssetTag, newAssetTag: AssetTag) => void,
  onRemove: (key: string, value: ?string) => void,
};

const AssetTagsEditDialogAssetTagRow = ({ assetTag, validation, onUpdate, onRemove }: Props) => {
  return (
    <Box display={'flex'} alignItems={'flex-start'}>
      <Box flexGrow={1} mr={1}>
        <AssetTagsEditDialogAssetTagRowKey
          assetTag={assetTag}
          disabled={Boolean(assetTag.imported_from)}
          assetTagValidationMessage={validation?.message}
          onUpdate={newAssetTag => onUpdate(assetTag, newAssetTag)}
        />
      </Box>

      <Box flexGrow={1} mr={1}>
        <AssetTagsEditDialogAssetTagRowValue
          assetTag={assetTag}
          disabled={Boolean(assetTag.imported_from) || !assetTag.tag.length}
          onUpdate={newAssetTag => onUpdate(assetTag, newAssetTag)}
        />
      </Box>
      {assetTag.imported_from && assetTag.imported_from_icon_url ? (
        <Box>
          <IconButton disabled>
            <img height={24} width={24} src={assetTag.imported_from_icon_url} />
          </IconButton>
        </Box>
      ) : assetTag.imported_from ? (
        <Box>
          <IconButton disabled>
            <LabelIcon />
          </IconButton>
        </Box>
      ) : (
        <Box>
          <IconButton aria-label={`Asset tag remove`} onClick={() => onRemove(assetTag.tag, assetTag.value)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default memo<Props>(AssetTagsEditDialogAssetTagRow);
