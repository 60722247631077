//@flow
import React from 'react';
import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';
import { palette } from '@dt/theme';

type ChipValue = { chipValue: string, chipValueExtended: string };

type Props = {
  +label: string,
  +value: string | ChipValue,
  +disabled?: boolean,
  +onDelete?: () => void,
};

const FilterChip = withStyles(() => ({
  root: {
    background: palette.white,
    color: palette.gray20,
    border: `0.5px solid ${palette.gray20}`,
    marginTop: 8,
    marginRight: 8,
  },
  deleteIcon: {
    color: palette.gray20,
    '&:hover': {
      color: palette.gray30,
    },
  },
}))(Chip);

const PopoutFilterChipComponent = function PopoutFilterChip({ label, value, disabled, onDelete }: Props) {
  let displayLabel;
  let displayTooltip;
  if (typeof value === 'string') {
    displayLabel = `${label} = ${value}`;
    displayTooltip = displayLabel;
  } else {
    displayLabel = `${label} = ${value.chipValue}`;
    displayTooltip = `${label} = ${value.chipValueExtended}`;
  }

  return (
    <Box display="inline-flex">
      <Tooltip disableInteractive title={displayTooltip}>
        <span>
          <FilterChip size={'small'} label={displayLabel} disabled={disabled} onDelete={onDelete} />
        </span>
      </Tooltip>
    </Box>
  );
};

export const getFilterChipMultipleValue = (options: any, optionDisplayLookup: any): null | ChipValue => {
  const entries = Object.entries(options)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => !!value);

  if (entries.length <= 0) {
    return null;
  }

  let firstEntry;
  if (optionDisplayLookup) {
    firstEntry = optionDisplayLookup[entries[0][0]];
  } else {
    firstEntry = entries[0][0].split('_').join(' ');
  }

  return {
    chipValue: `"${firstEntry}" ${(Number(entries.length) > 1 && '+ ' + (Number(entries.length) - 1) + ' more') || ''}`,
    chipValueExtended: entries
      // eslint-disable-next-line no-unused-vars
      .map(([key, _]) => {
        if (optionDisplayLookup) {
          return `"${optionDisplayLookup[key]}"`;
        } else {
          return key.split('_').join(' ');
        }
      })
      .join(', '),
  };
};

export const PopoutFilterChip = PopoutFilterChipComponent;
