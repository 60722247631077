//@flow
import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import Text from './Text';
import type { Node } from 'react';

type AdvancedCheckboxProps = {
  ariaLabel?: string,
  primaryText?: string | Node,
  secondaryText?: string | Node,

  // MUI Checkbox
  checked: boolean,
  color?: null | void | 'primary' | 'secondary' | 'default',
  disabled?: boolean,
  disableRipple?: boolean,
  onChange?: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  required?: ?boolean,
  size?: null | void | 'small' | 'medium',
};

/*
 * Checkbox with secondary text.
 *
 * [ ] Primary Text
 *     Secondary Text
 */
export const AdvancedCheckbox = function AdvancedCheckbox({
  ariaLabel,
  primaryText,
  secondaryText,
  ...props
}: AdvancedCheckboxProps) {
  return (
    <FormControlLabel
      label={
        <span
          style={{
            position: 'relative',
            top: !primaryText || !secondaryText ? 0 : 12,
          }}
        >
          {typeof primaryText === 'string' ? (
            <Text variant="body" style={{ marginTop: 0, marginBottom: 0, margin: 0 }}>
              {primaryText}
            </Text>
          ) : (
            primaryText
          )}
          {typeof secondaryText === 'string' ? (
            <Text variant="bodyS" style={{ marginTop: 0, marginBottom: 0, margin: 0 }}>
              {secondaryText}
            </Text>
          ) : (
            secondaryText
          )}
        </span>
      }
      control={
        <Checkbox
          color="primary"
          inputProps={{
            'aria-label': ariaLabel,
          }}
          {...props}
        />
      }
    />
  );
};
