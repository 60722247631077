//@flow
import React, { memo } from 'react';
import Skeleton from '@mui/material/Skeleton';

function ToolkitSkeleton() {
  return (
    <>
      <Skeleton animation="pulse" variant="circular" height={40} width={40} />
      <Skeleton animation="pulse" variant="text" height={60} width="70%" />
      <Skeleton animation="pulse" variant="rectangular" height={220} width="100%" />
      <Skeleton animation="pulse" variant="text" height={50} width={150} />
    </>
  );
}

export default memo<{}>(ToolkitSkeleton);
