// @flow
import React, { memo, useState } from 'react';
import _ from 'lodash';
import { Grid, TextField, List, ListItem, ListItemText, ListItemIcon, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import type { AssetsSearchFilterValue } from '@dt/graphql-support/types';

const useStyles = makeStyles(theme => ({
  mainList: {
    maxHeight: 350,
    overflow: 'scroll',
    overflowX: 'auto',
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(3),
  },
  itemLogo: {
    justifyContent: 'center',
  },
}));

type Props = {
  searchLabel?: string,
  list: ?Array<?AssetsSearchFilterValue>,
  selectedList: Array<?string>,
  onChange: (Array<?Object>) => void,
};

const InventoryTableFilterGroupComponent = function InventoryTableFilterGroup({
  searchLabel,
  list,
  selectedList,
  onChange,
}: Props) {
  const classes = useStyles();

  const [keywordState, setKeywordState] = useState<string>('');

  const keywordContains = item => {
    return _.includes(item?.toLowerCase(), keywordState.toLowerCase());
  };

  const assetsWithoutCategory = list?.filter(asset => !asset?.value_category);

  const assetsWithCategory = _.chain((list || []).filter(asset => asset?.value_category))
    // Group the elements of Array based on `color` property
    .groupBy('value_category')
    // `key` is group's name (color), `value` is the array of objects
    .map((value, key) => ({ value: key, assets: value }))
    .value();

  const isAtLeastOneItemSelected = (items: $ReadOnlyArray<?string>) =>
    _.difference([...items], [...selectedList]).length < items.length;

  const areAllItemsSelected = (items: $ReadOnlyArray<?string>) =>
    _.difference([...items], [...selectedList]).length === 0;

  const handleToggleItems = (selectedItems: Array<?string>) => {
    if (areAllItemsSelected(selectedItems)) {
      onChange(selectedList.filter(el => !selectedItems.includes(el)));
    } else {
      const restItems = selectedList.filter(el => !selectedItems.includes(el));
      onChange([...restItems, ...selectedItems]);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={keywordState}
          onChange={e => setKeywordState(e.target.value)}
          id="hosted-on-keyword"
          label={searchLabel || 'Search'}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <List className={classes.mainList} component="nav">
          {assetsWithCategory.map((category, key) => (
            <React.Fragment key={key}>
              {category.value && !keywordState && (
                <ListItem button dense onClick={() => handleToggleItems(category.assets.map(el => el.value))}>
                  <Checkbox
                    checked={isAtLeastOneItemSelected(category.assets.map(el => el.value))}
                    indeterminate={(() => {
                      const categoryItems = category.assets.map(el => el.value);
                      return isAtLeastOneItemSelected(categoryItems) && !areAllItemsSelected(categoryItems);
                    })()}
                  />
                  <ListItemText primary={category.value} />
                </ListItem>
              )}
              <List className={category.value ? classes.list : null}>
                {category.assets.map(
                  (asset, key) =>
                    (!keywordState || keywordContains(asset?.name)) && (
                      <ListItem key={key} button dense onClick={() => handleToggleItems([asset?.value])}>
                        <Checkbox checked={selectedList.includes(asset?.value)} />
                        <ListItemIcon className={classes.itemLogo}>
                          <img height={24} width={24} src={asset.icon_url} alt={asset.name} />
                        </ListItemIcon>
                        <ListItemText primary={asset.name} />
                      </ListItem>
                    ),
                )}
              </List>
            </React.Fragment>
          ))}
          {assetsWithoutCategory?.map(
            (asset, key) =>
              (!keywordState || keywordContains(asset?.name)) && (
                <ListItem key={key} button dense onClick={() => handleToggleItems([asset?.value])}>
                  <Checkbox checked={selectedList.includes(asset?.value)} />
                  <ListItemIcon className={classes.itemLogo}>
                    <img height={24} width={24} src={asset?.icon_url} alt={asset?.name} />
                  </ListItemIcon>
                  <ListItemText primary={asset?.name} />
                </ListItem>
              ),
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export default memo<Props>(InventoryTableFilterGroupComponent);
