// @flow
import React from 'react';
import { palette } from '@dt/theme';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, Divider } from '@mui/material';
import NetworkServiceIcon from '@mui/icons-material/Language';
import ArrowRight from '@mui/icons-material/KeyboardArrowRight';
import type { SecurityPropertiesTypeEnum, PolicyRuleTypeRelevanceType } from '@dt/horizon-api';

import RelevanceAvatar from './RelevanceAvatar';
import CertificateChainsProperties from './certificate-chains-properties';

type Props = {|
  +certificate_chain: {
    +security_properties: $ReadOnlyArray<{
      +enum: SecurityPropertiesTypeEnum,
      +value: boolean,
      +relevance: ?PolicyRuleTypeRelevanceType,
      ...
    }>,
    +certificates: $ReadOnlyArray<{
      +not_valid_after: Date,
      +issuer_as_rfc4514_string: string,
      +public_key_algorithm: string,
      +signature_hash_algorithm: string,
      +public_key_size: number,
      +domain_names_in_cn: $ReadOnlyArray<string>,
      ...
    }>,
    ...
  },
|};

type SecurityPropertyLabelProps = {|
  +security_property: {
    +enum: SecurityPropertiesTypeEnum,
    +value: boolean,
    +relevance: ?PolicyRuleTypeRelevanceType,
    ...
  },
  +certificate_chain: {
    +certificates: $ReadOnlyArray<{
      +not_valid_after: Date,
      ...
    }>,
    ...
  },
|};

const SecurityPropertyLabel = ({ security_property, certificate_chain }: SecurityPropertyLabelProps) => {
  let data = CertificateChainsProperties[security_property.enum];
  if (!data) return null;

  const text = typeof data.text === 'function' ? data.text(security_property.value, certificate_chain) : data.text;
  return <span>{text}</span>;
};

export default function CertificateChainCard({ certificate_chain }: Props) {
  const certificate = certificate_chain.certificates[0];

  const subjects = certificate_chain.certificates.map(cert => cert.domain_names_in_cn[0]);

  const issuer = (
    certificate_chain.certificates[0].issuer_as_rfc4514_string.split(',').find(l => l.includes('CN=')) || ''
  ).replace('CN=', '');

  return (
    <List component="nav">
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <NetworkServiceIcon fontSize="default" style={{ color: palette.gray20 }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={subjects.map((subject, index) => (
            <React.Fragment key={index}>
              <span
                style={{
                  fontWeight: index === 0 ? '500' : 'normal',
                  color: index === 0 ? palette.gray20 : palette.gray30,
                }}
              >
                {subject}
              </span>
              {subjects.length - 1 !== index ? (
                <ArrowRight
                  style={{
                    color: palette.gray20,
                    width: 14,
                    verticalAlign: 'middle',
                  }}
                />
              ) : (
                ''
              )}
            </React.Fragment>
          ))}
          secondary={`Key ${certificate.public_key_algorithm} ${certificate.public_key_size}${
            issuer ? ` issued by ${issuer}` : ''
          } with signature ${certificate.signature_hash_algorithm}`}
        />
      </ListItem>
      <Divider />
      {certificate_chain.security_properties.map((security_property, i) => {
        return (
          <React.Fragment key={i}>
            <ListItem>
              <ListItemAvatar>
                <RelevanceAvatar relevance={security_property.relevance} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <SecurityPropertyLabel security_property={security_property} certificate_chain={certificate_chain} />
                }
              />
            </ListItem>
            {i !== certificate_chain.security_properties.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </List>
  );
}
