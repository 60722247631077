//@flow
import gql from 'graphql-tag';

const v2_get = gql`
  query MobileApplicationDetailsGetV2($id: ID, $include: String) {
    mobile_app_details_v2(id: $id, include: $include) {
      id
      bundle_id
      discovered_via
      discovered_via_icon_url
      discovered_via_name
      icon_url
      mobile_secure_portal_url
      included_supply_chain_secure_my_apps_details {
        related_third_party_assets_count
        related_third_party_assets_count_30_day_delta
        related_third_party_assets_count_with_violations_30_day_delta
        related_third_party_assets_with_violations_count
        related_third_party_cloud_resource_count
        related_third_party_cloud_resource_count_30_day_delta
        related_third_party_combined_apis_count
        related_third_party_combined_apis_count_30_day_delta
        related_third_party_mobile_sdk_count
        related_third_party_mobile_sdk_count_30_day_delta
        vendors_count
        vendors_count_30_day_delta
      }
      last_scan_date
      last_scanned_via_icon_url
      last_scanned_via_name
      last_scanned_via_should_be_highlighted
      name
      platform
      platform_icon_url
      platform_name
      release_type
      release_type_icon_url
      release_type_name
      sevenhell_customer_mobile_app_id
      store_url
      subscription_name
      version_id
    }
  }
`;

const getMobileProtectAppCoverage = gql`
  query MobileProtectAppCoverage($id: ID!) {
    mobile_protect_app_coverage(id: $id) {
      exercised_api_operations_count
    }
  }
`;

const link_app = gql`
  mutation MobileApplicationLinkApp($id: ID!, $body: MobileApplicationsLinkAppBody!) {
    mobile_applications_link_app(id: $id, body: $body) {
      id
    }
  }
`;

const stats = gql`
  query MobileApplicationStats {
    mobile_applications_stats {
      android_apps_count
      android_preprod_apps_count
      ios_apps_count
      ios_preprod_apps_count
      enterprise_apps_count
      mobile_apis_count
      total_open_policy_violations_count
      total_open_toolkit_policy_violations_count
      total_open_non_toolkit_policy_violations_count
      android_icon_url
      enterprise_icon_url
      ios_icon_url
      active_installs_count
      attacks_detected_count
      attacks_blocked_count
      apps_with_active_protection_count
      total_apps_count
      mobile_api_icon_url
      trend_period_in_days
      mobile_apis_count_change_in_trend_period
      enterprise_apps_count_change_in_trend_period
      ios_apps_count_change_in_trend_period
      android_apps_count_change_in_trend_period
    }
  }
`;

const list = gql`
  query MobileApplicationList(
    $cursor: String
    $filter_by_asset_type: [AssetTypeEnum]
    $filter_by_vulnerable_to_toolkit_hacks: [String]
    $filter_by_active_protection_status: [String]
    $filter_by_text: String
    $filter_by_external_id: String
    $filter_by_asset_group_id: [ID]
    $filter_by_platform: [String]
    $filter_by_cloud_provider: [HostedOn]
    $filter_by_discovered_via: [DiscoveredVia]
    $order_by: [MobileAppOrderBy]
    $filter_by_linked_to_customer_mobile_app_id: String
    $filter_by_link_candidate_type: String
    $filter_by_asset_tags: String
    $filter_by_named_filter: String
    $filter_by_subscription: [String]
    $page_size: Float
  ) {
    mobile_app_list(
      cursor: $cursor
      filter_by_asset_type: $filter_by_asset_type
      filter_by_vulnerable_to_toolkit_hacks: $filter_by_vulnerable_to_toolkit_hacks
      filter_by_active_protection_status: $filter_by_active_protection_status
      filter_by_text: $filter_by_text
      filter_by_external_id: $filter_by_external_id
      filter_by_asset_group_id: $filter_by_asset_group_id
      filter_by_platform: $filter_by_platform
      filter_by_cloud_provider: $filter_by_cloud_provider
      filter_by_discovered_via: $filter_by_discovered_via
      order_by: $order_by
      filter_by_linked_to_customer_mobile_app_id: $filter_by_linked_to_customer_mobile_app_id
      filter_by_link_candidate_type: $filter_by_link_candidate_type
      filter_by_asset_tags: $filter_by_asset_tags
      filter_by_named_filter: $filter_by_named_filter
      filter_by_subscription: $filter_by_subscription
      page_size: $page_size
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      mobile_applications {
        id
        name
        bundle_id
        version_id
        platform
        release_type
        release_type_name
        release_type_icon_url
        sevenhell_customer_mobile_app_id
        platform_icon_url
        platform_name
        icon_url
        store_url
        has_linked_apps
        toolkit_hacks_total_count
        toolkit_hacks_vulnerable_to_count
        toolkit_hacks_vulnerable_to
        open_policy_violations_count
        active_protection_status
        mobile_protect_attacks_detected_count
        last_scan_date
        belongs_to_asset_group {
          id
          name
        }
        asset_tag_keys {
          uuid
          name
        }
        last_scanned_via_icon_url
        last_scanned_via_name
        last_scanned_via_should_be_highlighted
        has_mobile_secure_subscription
        tags {
          id
          tag_id
          tag
          value
          imported_from
          imported_external_id
          imported_from_icon_url
        }
      }
    }
  }
`;

const get = gql`
  query MobileApplicationDetailsGetQuery($id: ID) {
    mobile_application_details(id: $id) {
      mobile_applications {
        id
        app_secure_id
        name
        platform
        icon_url
        most_recent_scan {
          end_date
          date_updated
          mobile_app_id
          start_date
          date_created
          app_version
          id
        }
      }
      restful_apis {
        id
        title
        base_url
        asset_type_name
        asset_type_icon_url
        date_created
        date_no_longer_accessible
        discovered_via
        api_operations {
          http_method
          path
          is_authenticated
          policy_violations {
            id
            status
            date_resolved
            violated_policy_rule {
              id
              relevance
            }
          }
          restful_api {
            id
            base_url
          }
        }
      }
      cloud_resources {
        id
        date_created
        name
        region
        resource_type
        asset_type_icon_url
        asset_type_name
        runtime
        status
        date_no_longer_accessible
        discovered_via
        hosted_on
        cloud_console_url
        url
        policy_violations {
          id
          status
          violated_policy_rule {
            id
            relevance
            policy_rule_type {
              id
              title
            }
          }
        }
      }
    }
  }
`;

export default {
  get,
  v2_get,
  list,
  stats,
  link_app,
  getMobileProtectAppCoverage,
};
