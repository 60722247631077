//@flow
import React, { memo, type Node } from 'react';
import { makeStyles } from '@mui/styles';
import { MobileAppsPlatformIcon } from '@dt/material-components';
import type { PlatformIconTypes } from '@dt/material-components';

const useStyles = makeStyles({
  IconLabel: {},

  icon: {
    width: 16,
    height: 16,
    verticalAlign: 'middle',
  },

  label: {
    verticalAlign: 'middle',
    marginLeft: 5,
  },
});

type Props = {|
  platform: PlatformIconTypes,
  children?: Node | void,
|};

export default memo<Props>(function IconLabel(props) {
  const { children, platform } = props;
  const classes = useStyles();

  return (
    <span className={classes.IconLabel}>
      <MobileAppsPlatformIcon platform={platform} className={classes.icon} />
      <span className={classes.label}>{children}</span>
    </span>
  );
});
