// @flow
import React, { type Node } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, AccordionActions, Chip } from '@mui/material';
import { withStyles, type WithStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Markdown } from '@dt/material-components';

const styles = {
  summaryRoot: {
    paddingRight: 24,
    paddingLeft: 24,
  },
  summaryContent: {
    cursor: 'default',
    flexDirection: 'column',
    marginRight: 32,
    '&$expanded': {
      marginRight: 32,
    },
  },
  expanded: {},
  summaryFirstRow: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 750px)': {
      flexWrap: 'wrap',
    },
  },
  summaryLeftSide: {
    display: 'flex',
    paddingRight: 8,
  },
  summaryCenter: {
    flexGrow: 1,
    paddingRight: 8,
  },
  summaryRightSide: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  summarySecondRow: {
    display: 'flex',
    paddingTop: 8,
  },
  ruleDetails: {
    flexDirection: 'column',
  },
  ruleDescriptionBody: {},
};

type OwnProps = {|
  title: string,
  description: string,
  summaryRightSide?: Node,
  summarySecondRow?: Node,
  children?: Node,
  actions?: Node,
  count?: ?number,
|};

type Props = {| ...OwnProps, ...WithStyles |};

/*
 * @deprecated In favor of using the 'components/policy_rules/PolicyRulesCard'.
 */
function PolicyRuleBase({
  title,
  description,
  summaryRightSide,
  summarySecondRow,
  actions,
  children,
  classes,
  count,
}: Props) {
  const id = `policy-rule-${title}`;
  return (
    <Accordion data-testid={id}>
      <AccordionSummary
        classes={{
          content: classes.summaryContent,
          root: classes.summaryRoot,
          expanded: classes.expanded,
        }}
        expandIcon={<ExpandMoreIcon style={{ cursor: 'pointer' }} />}
      >
        <div className={classes.summaryFirstRow}>
          <div className={classes.summaryLeftSide}>
            <Typography variant="subtitle1" style={{ maxWidth: 600, cursor: 'pointer' }}>
              {title}
            </Typography>
          </div>

          <div className={classes.summaryCenter}>
            {typeof count === 'number' && (
              <Chip label={`${count} Unresolved`} size="small" aria-label={`${count} Unresolved`} />
            )}
          </div>

          <div className={classes.summaryRightSide}>{summaryRightSide}</div>
        </div>
        <div className={classes.summarySecondRow}>{summarySecondRow}</div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.ruleDetails }}>
        <div className={classes.ruleDescriptionBody}>
          <Typography variant="body2" gutterBottom component="div">
            <Markdown inline text={description} />
          </Typography>
          {children}
        </div>
      </AccordionDetails>
      <AccordionActions>{actions}</AccordionActions>
    </Accordion>
  );
}

export default React.memo<OwnProps>(withStyles(styles)(PolicyRuleBase));
