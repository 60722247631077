//@flow
import React, { memo, type Node } from 'react';
import { palette, spacing } from '@dt/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  box: ({ absoluteLayout }) => ({
    ...(absoluteLayout
      ? {
          // Position absolute is used here to force reflow for right most containers.
          position: 'absolute',
          height: '100%',
        }
      : {}),
    minWidth: spacing.menuWidth,
    maxWidth: spacing.menuWidth,
    paddingTop: 12,
    borderRight: `1px solid ${palette.accent}`,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  }),
});

type Props = {
  absoluteLayout?: boolean,
  children: Node,
};

export default memo<Props>(function LHSMenu({ absoluteLayout, children }) {
  const css = useStyles({ absoluteLayout });
  return <div className={css.box}>{children}</div>;
});
