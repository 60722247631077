//@flow
import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { groupBy } from 'lodash/fp';

import CompliancePolicyLogo from './CompliancePolicyLogo';
import { type CompliancePolicyReference } from '@dt/findings/types';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  container: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  badge: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px',
    marginLeft: '.05em',
    marginRight: '.05em',
    color: palette.black,
    userSelect: 'none',
  },
});

type Props = {|
  compliance_policy_references: $ReadOnlyArray<CompliancePolicyReference>,
|};

function CompliancePolicyBanner(props) {
  const { compliance_policy_references } = props;
  const classes = useStyles();

  // Get all the possible compliance types.
  const compliancePolicyTypes = Object.keys(groupBy(ref => ref.compliance_policy)(compliance_policy_references || []));

  return (
    <div className={classes.container}>
      {compliancePolicyTypes.map(compliancePolicy => (
        <div key={compliancePolicy} className={classes.badge}>
          <CompliancePolicyLogo policy={compliancePolicy} size="small" />
        </div>
      ))}
    </div>
  );
}

export default memo<Props>(CompliancePolicyBanner);
