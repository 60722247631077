// @flow
import React, { memo } from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import Text from '../Text';

const useStyles = makeStyles({
  head: {
    '& th': {
      backgroundColor: palette.white,
      borderColor: palette.gray45,
      padding: 8,
      minWidth: 120,
    },
  },
});

function ToolkitAssetTableHead() {
  const classes = useStyles({});
  const {
    location: { pathname },
  } = window;
  const displayAuth = pathname.indexOf('cloud-leaks') === -1;

  return (
    <TableHead classes={{ root: classes.head }}>
      <TableRow>
        <TableCell align="center" colSpan={2}>
          <Text variant="titleS" component="span">
            Asset Details
          </Text>
        </TableCell>
        <TableCell align="left">
          <Text variant="titleS" component="span">
            Status History
          </Text>
        </TableCell>
        {displayAuth ? (
          <TableCell align="left">
            <Text variant="titleS" component="span">
              Auth Scheme
            </Text>
          </TableCell>
        ) : (
          <TableCell />
        )}
        <TableCell align="center">
          <Text variant="titleS" component="span">
            Current Status
          </Text>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default memo<{}>(ToolkitAssetTableHead);
