//@flow
import React, { type ElementConfig, memo } from 'react';
import MUIButton from '@mui/material/Button';
import MUICircularProgress from '@mui/material/CircularProgress';

import { fontSizes } from '@dt/theme';

const CircularProgress = () => (
  <MUICircularProgress
    size={fontSizes.medium + 10}
    color="inherit"
    thickness={2}
    // There is no way of giving a role or title to this SVG.
    // In the future, it material-ui should allow us to pass <title></title> tags to svg
    // so it can be accessible in our tests (and a11y)
    data-testid="__loading_button__"
  />
);

type Props = {
  ...ElementConfig<typeof MUIButton>,
  isLoading?: ?boolean,
};
export default memo<Props>(function Button({ isLoading, children, ...props }) {
  return <MUIButton {...props}>{isLoading ? <CircularProgress /> : children}</MUIButton>;
});
