//@flow
import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Card } from '@mui/material';

const useStyles = makeStyles({
  paper: {
    borderLeft: ({ status }) => {
      return typeof status !== 'function' ? status : status();
    },
    borderRadius: 2,
  },
});

export type Props = {|
  status: string | (() => string),
  children?: React.Node,
|};

const CardWithStatusIndicator = (props: Props) => {
  const classes = useStyles(props);
  return <Card className={classes.paper}>{props.children}</Card>;
};

export default CardWithStatusIndicator;
