// @flow
import React, { memo } from 'react';
import { useQuery } from '@dt/apollo-link-schema-rest';
import { Grid, List, ListItem, Checkbox, TablePagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';
import { ErrorState } from '@dt/components';
import { Text } from '@dt/material-components';
import asset_groups from '@dt/graphql-support/horizon/asset_groups';
import type { V2AssetGroupsListQuery, V2AssetGroupsListQueryVariables, V2AssetGroup } from '@dt/graphql-support/types';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  mainList: {
    height: 300,
    overflow: 'scroll',
    overflowX: 'auto',
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(3),
  },
  itemLogo: {
    justifyContent: 'center',
  },
}));

type Props = {
  selectedAssetGroupIds: Array<?string>,
  onChange: (Array<?string>) => void,
};

const AssetFilterAssetGroup = ({ selectedAssetGroupIds, onChange }) => {
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const rowsPerPage = 10;

  const { data, loading, error, fetchMore } = useQuery<V2AssetGroupsListQuery, V2AssetGroupsListQueryVariables>(
    asset_groups.v2_list,
    {
      variables: {
        page_size: 10,
      },
    },
  );

  if (error) {
    return <ErrorState error={error.message} />;
  }

  if (loading) {
    return (
      <Grid container spacing={1} justifyContent={'center'}>
        {[...new Array(6)].map((_, key) => (
          <Grid item xs={10} key={key}>
            <Skeleton animation="wave" width={`100%`} height={40} />
          </Grid>
        ))}
      </Grid>
    );
  }

  const paginationInformation = data?.asset_group_list_v2.pagination_information;
  const totalCount = paginationInformation?.total_count ?? 0;
  const items = data?.asset_group_list_v2.asset_groups || [];
  const noItems = totalCount === 0;

  const handleChangePage = (event, newPage) => {
    if ((items.length || 0) / rowsPerPage / (newPage + 1) <= 1) {
      fetchMore && fetchMore();
    }
    setPage(newPage);
  };

  const handleToggleAssetGroup = (selectedAssetGroup: V2AssetGroup) => {
    if (selectedAssetGroupIds.includes(selectedAssetGroup.id)) {
      onChange(selectedAssetGroupIds.filter(el => el !== selectedAssetGroup.id));
    } else {
      onChange([...selectedAssetGroupIds, selectedAssetGroup.id]);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <List className={classes.mainList} component="nav">
          {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((assetGroup, key) => (
            <ListItem button dense key={key} onClick={() => handleToggleAssetGroup(assetGroup)}>
              <Checkbox checked={selectedAssetGroupIds.includes(assetGroup.id)} />
              <Text noWrap>{assetGroup.name}</Text>
            </ListItem>
          ))}
        </List>
        {!noItems && (
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            page={page}
            onPageChange={handleChangePage}
            nextIconButtonProps={loading ? { disabled: true } : null}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default memo<Props>(AssetFilterAssetGroup);
