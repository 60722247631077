//@flow
import React, { useEffect, useRef } from 'react';
import { LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const timeToComplete = 0.95;
const useStyles = makeStyles({
  root: {
    height: 8,
  },
  bar1Indeterminate: ({ delay }) => ({
    backgroundColor: palette.red,
    animationDelay: `${-1 * delay * timeToComplete}s`,
  }),
  bar2Indeterminate: ({ delay }) => ({
    backgroundColor: palette.red,
    animationDelay: `${-1 * (delay * timeToComplete + timeToComplete)}s`,
  }),
  bar1Determinate: {
    backgroundColor: palette.green,
  },
  colorPrimaryInProgress: {
    backgroundColor: palette.red50,
  },
  colorPrimaryCompleted: {
    backgroundColor: palette.green50,
  },
});

type Props = {|
  /*
   * Time to delay the animation. Represented as a percentage (0.2) for a 20% delay.
   * Used to prevent all progress animations on a page to run at the same pace.
   */
  +delay?: number,
  /*
   * Show a completed progress bar? If not present will progress indefinitely.
   */
  +isCompleted?: boolean,
  /*
   * Sets the indicator to randomly complete within the random complete interval.
   * This is a controlled component so the client must set `isCompleted` when `onRandomComplete` fires.
   */
  +randomComplete?: boolean,
  +randomCompleteInterval?: number,
  +onRandomComplete?: () => void,
|};

/*
 * MaterialUI LinearProgress with the ability delay the progress animation.
 */
function LinearProgressIndeterminate(props: Props) {
  // Default props - Used instead of defaultProps for randomness.
  let delay;
  if (typeof props.delay !== 'number') {
    delay = Math.random();
  } else {
    delay = props.delay;
  }
  let randomCompleteInterval;
  if (typeof props.randomCompleteInterval !== 'number') {
    randomCompleteInterval = Math.floor(Math.random() * 60 * 1) * 1000;
  } else {
    randomCompleteInterval = props.randomCompleteInterval;
  }

  const classes = useStyles({ ...props, delay });
  const { isCompleted, onRandomComplete, randomComplete } = props;

  const isRunning = useRef<boolean>(false);

  useEffect(() => {
    // Force completion?
    if (isCompleted) {
      isRunning.current = false;
      return;
    }

    if (isRunning.current) {
      return;
    }

    // Complete within random time interval.
    let completeTimeout = null;
    if (randomComplete) {
      completeTimeout = setTimeout(() => {
        if (!isRunning.current) {
          return;
        }
        isRunning.current = false;
        onRandomComplete && onRandomComplete();
      }, randomCompleteInterval);
    }

    const reset = () => {
      completeTimeout && clearTimeout(completeTimeout);
      completeTimeout = null;
    };

    isRunning.current = true;
    return reset;
  }, [isCompleted]); // eslint-disable-line
  // When other state is passed to 'useEffect' completion stops working.

  return (
    <>
      {!isCompleted ? (
        <LinearProgress
          variant="indeterminate"
          classes={{
            root: classes.root,
            bar1Indeterminate: classes.bar1Indeterminate,
            bar2Indeterminate: classes.bar2Indeterminate,
            colorPrimary: classes.colorPrimaryInProgress,
          }}
        />
      ) : (
        <LinearProgress
          variant="determinate"
          classes={{
            root: classes.root,
            bar1Determinate: classes.bar1Determinate,
            colorPrimary: classes.colorPrimaryCompleted,
          }}
          value={100}
        />
      )}
    </>
  );
}

export default LinearProgressIndeterminate;
