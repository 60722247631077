//@flow
import React from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { palette, fontSizes } from '@dt/theme';
import Add from '@mui/icons-material/Add';
import Text from './Text';

type Comment = {
  +id: ?string,
  +author_email: ?string,
  +date: ?string,
  +is_internal_comment: boolean,
  +text: ?string,
  ...
};

type Props = {
  +sortedComments: $ReadOnlyArray<Comment>,
  +onOpenWorkflow: () => void,
  +isV2?: ?boolean,
};

/*
 * DO NOT use directly.
 *
 * Header details for "Advice/Notes" used by the following components:
 *
 * - PolicyViolationsCommentsSection
 * - PolicyViolationsCommentsSectionCard
 */
const PolicyViolationsCommentsSectionHeaderComponent = function PolicyViolationsCommentsSectionHeader({
  sortedComments,
  onOpenWorkflow,
  isV2,
}: Props) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Text
        variant="titleXS"
        color={palette.gray20}
        style={{ margin: 0, textTransform: isV2 ? undefined : 'uppercase' }}
        className="notes-heading"
      >
        {isV2 ? 'AppSec Advice' : `Advice/Notes (${sortedComments.length})`}
      </Text>

      {!isV2 && (
        <Button
          style={{
            marginLeft: 16,
            height: 30,
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 12,
            paddingRight: 12,
            color: palette.gray20,
            backgroundColor: palette.gray45,
            minWidth: 'unset',
          }}
          onClick={onOpenWorkflow}
        >
          <Add style={{ fontSize: fontSizes.large, marginBottom: 2 }} />
          <span style={{ marginLeft: 8, whiteSpace: 'pre' }}>
            <Text variant="button" color="inherit">
              Appsec Advice/Internal Notes
            </Text>
          </span>
        </Button>
      )}
    </Box>
  );
};

export const PolicyViolationsCommentsSectionHeader = PolicyViolationsCommentsSectionHeaderComponent;
