//@flow
import React from 'react';
import capitalize from 'lodash/capitalize';
import { Grid, FormControlLabel, Checkbox } from '@mui/material';
import { WeekdaysEnum } from '@dt/horizon-api';
import type { Weekdays } from '@dt/horizon-api';

type Props = {
  selectedWeekdays: $ReadOnlyArray<Weekdays>,
  onChange: (weekdays: $ReadOnlyArray<Weekdays>) => void,
  variant?: 'linear' | 'two-columns',
};

const WeekdaysCheckbox = ({ selectedWeekdays, onChange, variant = 'two-columns' }: Props) => {
  return (
    <Grid container justifyContent={'flex-start'} spacing={1}>
      {Object.keys(WeekdaysEnum).map((weekday, index) => (
        <Grid item xs={variant === 'two-columns' ? 6 : null} key={index}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                onChange={e => {
                  const weekdays = selectedWeekdays.filter(item => item !== weekday);
                  const isChecked = e.target.checked;
                  onChange(isChecked ? weekdays.concat(weekday) : weekdays);
                }}
                checked={selectedWeekdays.includes(weekday)}
                value={weekday}
                style={{ paddingTop: 3, paddingBottom: 3 }}
              />
            }
            label={capitalize(weekday)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default React.memo<Props>(WeekdaysCheckbox);
