// @flow
import React, { memo, useState } from 'react';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import ExtLink from '../ExtLink';
import type { DTProductConfig } from './ConfigurableLHSMenuPage';

let useStyle = makeStyles({
  menuOuter: {
    alignItems: 'center',
    borderRadius: 4,
    color: palette.white,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 8,
    marginLeft: 16,
    marginRight: 16,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    '&:hover': {
      backgroundColor: palette.brand20,
    },

    '&.active': {
      backgroundColor: palette.brand35,
    },

    '&.bottom': {
      marginTop: 'auto',
    },
  },

  menuInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  menuIconBox: {
    maxWidth: 40,
    maxHeight: 40,
    minWidth: 40,
    minHeight: 40,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px',
  },

  menuText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&.title': {
      color: palette.white,
      fontSize: 20,
    },
  },
});

type Props = {
  products: $ReadOnlyArray<DTProductConfig>,
};

function ConfigurableLHSMenuProductSwitcher({ products }: Props) {
  const css = useStyle();

  const [switcherAnchorEl, setSwitcherAnchorEl] = useState(null);
  const handleOpenProductSwitcher = event => setSwitcherAnchorEl(event.currentTarget);
  const handleCloseProductSwitcher = () => setSwitcherAnchorEl(null);

  return (
    <>
      <div
        className={css.menuOuter}
        type="button"
        onClick={event => handleOpenProductSwitcher(event)}
        aria-controls="switcher-menu"
        aria-haspopup="true"
        aria-label="Switch To Menu"
      >
        <Tooltip disableInteractive title="Switch to">
          <div className={css.menuInner}>
            <div className={css.menuIconBox}>
              <AppsIcon color="inherit" />
            </div>
            <div className={css.menuText}>Products</div>
          </div>
        </Tooltip>
      </div>

      {/* Product Switcher Menu */}
      <Menu
        id="switcher-menu"
        keepMounted
        anchorEl={switcherAnchorEl}
        open={Boolean(switcherAnchorEl)}
        onClose={handleCloseProductSwitcher}
        getContentAnchorEl={null}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        <div>
          {/* Products section */}
          {products.map((product, idx) => {
            const DTProductIcon = product.icon;

            return (
              <ExtLink key={idx} to={product.to} target="_self" onClick={handleCloseProductSwitcher}>
                <MenuItem>
                  <div className={css.menuIconBox}>
                    {/* Blue icon */}
                    <DTProductIcon foregroundColor={palette.white} backgroundColor={palette.brand30} />
                  </div>
                  {product.name}
                </MenuItem>
              </ExtLink>
            );
          })}
        </div>
      </Menu>
    </>
  );
}

export default memo<Props>(ConfigurableLHSMenuProductSwitcher);
