// @flow
import React, { memo } from 'react';
import Banner from './Banner';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { palette } from '@dt/theme';
import format from 'date-fns/format';

type Props = {
  assetTypeName: string,
  url?: ?string,
  lastUpdated?: ?Date,
};

export default memo<Props>(function OfflineIndicator({ assetTypeName, url, lastUpdated }: Props) {
  return (
    <Banner status="critical" inline icon={<NotificationImportantIcon />} iconColor={palette.red30}>
      {`This ${assetTypeName} was unreachable ${url ? `at ${url}` : ''} ${
        lastUpdated ? `on ${format(lastUpdated, 'MMM DD YYYY')}` : ''
      }. It may have been removed from the environment.`}
    </Banner>
  );
});
